import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

import SEO from '../components/seo';
import { Typography, Container, Box, Grid } from '@material-ui/core';
import { PersonBySlugQuery } from '../../types/graphql-types';
import AvatarCircleBlock from '../components/image/avatar-circle-block';

export interface MemberPageProps {
  data: PersonBySlugQuery;
}

const MemberPage = ({ data }: MemberPageProps): JSX.Element => {
  const { contentfulPerson } = data;

  return (
    <>
      <SEO title={contentfulPerson?.title || ''} />
      <Box mb={5} mt={5}>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <AvatarCircleBlock>
                {contentfulPerson?.image?.gatsbyImageData && (
                  <GatsbyImage
                    image={contentfulPerson.image.gatsbyImageData as IGatsbyImageData}
                    alt={contentfulPerson?.name || 'Profile'}
                  />
                )}
              </AvatarCircleBlock>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Typography variant="h6" gutterBottom>
                Name
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                {contentfulPerson?.name}
              </Typography>
              <Typography variant="h6" gutterBottom>
                Title
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                {contentfulPerson?.title}
              </Typography>
              <Typography variant="h6" gutterBottom>
                Email
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                {contentfulPerson?.email}
              </Typography>
              <Typography variant="h6" gutterBottom>
                Biography
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                {contentfulPerson?.shortBio?.shortBio}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default MemberPage;

export const pageQuery = graphql`
  query PersonBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulPerson(slug: { eq: $slug }) {
      email
      name
      title
      slug
      shortBio {
        shortBio
      }
      image {
        gatsbyImageData(width: 550, height: 550, quality: 90)
      }
    }
  }
`;
